// Don't import from index so that we can keep bundle size as low as possible
// Don't import from index so that we can keep bundle size as low as possible

// eslint-disable-next-line no-restricted-imports
import { localeMatches } from '@trello/locale/src/localeMatches';
// eslint-disable-next-line no-restricted-imports
import { normalizeLocale } from '@trello/locale/src/normalizeLocale';

import { getSupportedLocales } from './getSupportedLocales';

const DEFAULT_LOCALE = 'en-US';

export function getDefaultLocale() {
  for (const language of navigator.languages) {
    const normalizedLanguage = normalizeLocale(language);

    const match = getSupportedLocales().find((locale) =>
      localeMatches(locale, normalizedLanguage),
    );

    if (match) {
      return match;
    }
  }

  return DEFAULT_LOCALE;
}
